import React from 'react';
import 'bootstrap/dist/js/bootstrap.min.js';

const AdminMenu = (props) => {

    return (
        <div className={`position-fixed fixed-bottom user-menu dropup border-top col-12 col-md-3 col-lg-2 admin-menu border-end ${props.isSideBar ? 'sidebar-show' : 'sidebar-hide'}`}>
            <button
                className="list-group-item list-group-item-action p-3 text-truncate border-0 text-light rounded dropdown-toggle d-flex align-items-center"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <img src="/user.png" alt="user" width="25" className="me-2" />
                <span>{props.user.name}</span>
            </button>
            <ul className="dropdown-menu">
                <li>
                    <button disabled
                        type="button"
                        className="dropdown-item"
                    >
                        <i className="bi bi-wallet2 pe-2"></i> Billing
                    </button>
                </li>
                <li>
                    <button
                        type="button"
                        className="dropdown-item"
                        onClick={() => {
                            props.setSelectedScreen('AdminUserScreen');
                            props.setScrollDirection(1);
                        }}
                    >
                        <i className="bi bi-people pe-2"></i> Users
                    </button>
                </li>
                <li>
                    <button
                        type="button"
                        className="dropdown-item"
                        onClick={() => {
                            props.setSelectedScreen('AdminDataSourcesScreen');
                            props.setScrollDirection(1);
                        }}
                    >
                        <i className="bi bi-database pe-2"></i> Data Sources
                    </button>
                </li>
                <li><hr className="dropdown-divider" /></li>
                <li>
                    <button disabled
                        type="button"
                        className="dropdown-item"
                    >
                        <i className="bi bi-person pe-2"></i> Profile settings
                    </button>
                </li>
                <li>
                    <button
                        type="button"
                        className="dropdown-item"
                        onClick={props.handleLogout}>
                        <i className="bi bi-power pe-2"></i> Log Out
                    </button>
                </li>
            </ul>
        </div>

    );
};
export default AdminMenu;