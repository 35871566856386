import React, { useState } from "react";
import axios from "axios";
import { ArrowClockwise, Gear, Play, Trash, ThreeDotsVertical, InfoCircle } from 'react-bootstrap-icons';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import DataSourceDeleteModal from "./DataSourceDeleteModal";
import DataSourceUpdateModal from "./DataSourceUpdateModal";
import config from "../../config.json"; // Configuration file for service URLs and other settings

function DataSourceCard({ savedsource, setConductReload }) {
  const imageSrc = `${savedsource.source.icon_url}`; // Fetch the icon URL from the data source

  // State variables to manage modals and actions
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // State variables to manage status and loading states
  const [status, setStatus] = useState("Unprocessed"); // Default status of the data source
  const [isRefreshing, setIsRefreshing] = useState(false); // Indicates if the status is being refreshed
  const [isProcessing, setIsProcessing] = useState(false); // Indicates if the "Process" action is ongoing
  const [statusColor, setStatusColor] = useState("bg-secondary"); // Gray color for unprocessed

  const [counts, setCounts] = useState({
    already_processed: 0,
    success: 0,
    failed: 0,
  });


  // Handle opening the update modal
  const handleUpdateClick = () => {
    setShowUpdateModal(true);
  };

  // Handle opening the delete modal
  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  // Handle the "Process" action
  const handleProcessClick = async () => {
    setIsProcessing(true); // Indicate that processing has started
    try {
      const payload = {
        fulfillment_source_id: savedsource.id,
      };
  
      const headers = {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"), // Use the stored token
      };
  
      // Call the process API
      const response = await axios.post(`${config.serviceUrl}/documents/process`, payload, { headers });
      console.log("Process successful:", response.data);
  
      // Update counts and status
      setCounts(response.data); // Update counts with the response
      updateStatusFromResponse(response.data, setStatus, setStatusColor); // Dynamically update status and color
    } catch (error) {
      console.error("Error during processing:", error);
      setStatus("Failed"); // Set status to "Failed" on error
      setStatusColor("bg-danger"); // Red
    } finally {
      setIsProcessing(false); // Reset the processing state
    }
  };

  // Handle refreshing the status
  const handleRefreshClick = async () => {
    setIsRefreshing(true); // Start the refreshing state
    try {
      console.log("Refreshing status...");
      // Simulate an API call to get the latest status
      const latestStatus = await new Promise((resolve) =>
        setTimeout(() => resolve("Success"), 1000)
      ); // Replace with actual API call
      setStatus(latestStatus); // Update status with the latest value
    } catch (error) {
      console.error("Failed to refresh status:", error);
    } finally {
      setIsRefreshing(false); // End the refreshing state
    }
  };

  // Determine the badge color based on the status
  const getStatusBadgeColor = (status) => {
    switch (status) {
      case "Processing":
        return "bg-warning"; // Yellow for processing
      case "Unprocessed":
        return "bg-secondary"; // Gray for unprocessed
      case "Success":
        return "bg-success"; // Green for success
      case "Fail":
        return "bg-danger"; // Red for failure
      default:
        return "bg-secondary"; // Default gray color
    }
  };

  const calculateStatusFromResponse = (response) => {
    const { failed, success, already_processed } = response;
  
    if (failed > 0 && success === 0) {
      // All failed
      return { status: "Failed", color: "bg-danger" }; // Red
    } else if (failed > 0 && success > 0) {
      // Some failed, some succeeded
      return { status: "Partial Success", color: "bg-warning" }; // Orange
    } else if (failed === 0 && success > 0) {
      // All succeeded
      return { status: "Success", color: "bg-success" }; // Green
    }
  
    // Default fallback
    return { status: "Unknown", color: "bg-secondary" }; // Gray
  };
  
  const updateStatusFromResponse = (apiResponse, setStatus, setStatusColor) => {
    const { status, color } = calculateStatusFromResponse(apiResponse);
    setStatus(status);
    setStatusColor(color);
  };

  return (
    <>
      <div key={savedsource.id} className="col-md-4 col-12 mb-4">
        <div className="card shadow-sm border-0">
          <div className="card-body">
            {/* Row containing the title, refresh button, and badge */}
            <div className="row align-items-center">
              <div className="col">
                <h5 className="card-title mb-2 font-weight-bold text-left">
                  {savedsource.name}
                </h5>
              </div>
              <div className="col-auto d-flex align-items-center">
                {/* Refresh Button */}
                {/* <button
                  type="button"
                  onClick={handleRefreshClick}
                  disabled={isRefreshing} // Disable the button while refreshing
                  style={{
                    border: "none",
                    background: "none",
                    padding: 0,
                    cursor: "pointer",
                  }}
                >
                  <ArrowClockwise
                    className={`me-2 ${isRefreshing ? "spinner-border spinner-border-sm" : ""}`}
                  />
                </button> */}
                {/* Status Badge with Tooltip */}
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-${savedsource.id}`} className="text-start">
                      <div>
                        <strong>Status:</strong> {status} <br />
                        {status !== "Unprocessed" && status !== "Failed" && (
                          counts.success || counts.failed || counts.already_processed ? ( // Show counts only if available
                            <>
                              <strong>Details:</strong>
                              <ul className="mb-0">
                                <li><strong>Processed:</strong> {counts.already_processed}</li>
                                <li><strong>Success:</strong> {counts.success}</li>
                                <li><strong>Failed:</strong> {counts.failed}</li>
                              </ul>
                            </>
                          ) : (
                            <span>Processing data...</span> // Show this only during processing
                          )
                        )}
                        {status === "Failed" && <span>All items failed. Please retry.</span>} {/* Show specific message for failure */}
                      </div>
                    </Tooltip>
                  }
                >
                  <span
                    className={`badge me-2 ${statusColor}`}
                    style={{ cursor: "help" }}
                  >
                    {status}{" "}
                    <InfoCircle
                      size={14}
                      className="ms-1"
                      style={{ verticalAlign: "middle" }}
                    />
                  </span>
                </OverlayTrigger>

                {/* Source Icon */}
                <img
                  src={imageSrc}
                  height="40"
                  className="float-end rounded ms-2"
                  alt={`${savedsource.name} Logo`}
                />
              </div>
            </div>
            {/* Buttons for Update and Delete */}
            <div className="d-flex justify-content-between mt-3 d-none d-lg-flex">
              <button
                type="button"
                className="btn btn-outline-elastiq"
                onClick={handleUpdateClick}
              >
                <Gear className="me-1 mb-1" />
                Settings
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={handleProcessClick}
                disabled={isProcessing} // Disable the button while processing
              >
                {isProcessing ? (
                  <>
                    <div className="spinner-border spinner-border-sm me-2" role="status" />
                    Processing...
                  </>
                ) : (
                  <>
                    <Play className="me-1 mb-1" />
                    Process
                  </>
                )}
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteClick}
              >
                <Trash className="me-1 mb-1" />
                Delete
              </button>
            </div>

            {/* Dropdown for medium screens */}
            <div className="d-lg-none mt-3 text-end">
              <div className="dropdown">
                <button
                  className="btn btn-outline-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <ThreeDotsVertical />
                </button>
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={handleProcessClick}
                    >
                      <Play className="me-2" />
                      Process
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={handleUpdateClick}
                    >
                      <Gear className="me-2" />
                      Settings
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={handleDeleteClick}
                    >
                      <Trash className="me-2" />
                      Delete
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modals for Update and Delete */}
      {showUpdateModal && (
        <DataSourceUpdateModal
          savedsource={savedsource}
          showUpdateModal={showUpdateModal}
          setShowUpdateModal={setShowUpdateModal}
          setConductReload={setConductReload}
        />
      )}

      {showDeleteModal && (
        <DataSourceDeleteModal
          savedsource={savedsource}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          setConductReload={setConductReload}
        />
      )}
    </>
  );
}

export default DataSourceCard;
