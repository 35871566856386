import React, { useState, useEffect } from "react";

function MicButton({
  isTyping,
  speechRecognitionRef,
  setInterimTranscript,
  setInputText,
  setIsMicActive,
  isMicActive,
}) {
  const [recognitionAvailable, setRecognitionAvailable] = useState(false);

  // Test if webkitSpeechRecognition is available in the browser or not
  useEffect(() => {
    if (!("webkitSpeechRecognition" in window)) {
      console.warn("Web Speech API not supported in this browser.");
    } else {
      setRecognitionAvailable(true);
    }
  }, [recognitionAvailable]);
  //Cleanup SpeechRecognitionRef instance
  useEffect(() => {
    return () => {
      if (speechRecognitionRef.current) {
        speechRecognitionRef.current.stop();
      }
    };
  }, [speechRecognitionRef]);

  const handleMicClick = () => {
    if (!speechRecognitionRef.current) {
      const recognition = new window.webkitSpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = true;

      recognition.onresult = (event) => {
        let interimTranscript = "";
        let finalTranscript = "";
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          const transcript = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            finalTranscript += transcript;
          } else {
            interimTranscript += transcript;
          }
        }
        setInterimTranscript(interimTranscript);
        if (finalTranscript) {
          setInputText((prevText) => prevText + finalTranscript);
        }
      };

      recognition.onerror = (event) => {
        console.error("Speech Recognition Error: ", event.error);
        setIsMicActive(false);
      };

      speechRecognitionRef.current = recognition;
    }

    if (speechRecognitionRef.current) {
      if (isMicActive) {
        speechRecognitionRef.current.stop();
      } else {
        speechRecognitionRef.current.start();
      }
      setIsMicActive(!isMicActive);
    }
  };

  return (
    <>
      {recognitionAvailable ? (
        <button
          className={`btn ${
            isMicActive ? "btn-danger" : "btn-light"
          } border p-3`}
          onClick={handleMicClick}
          disabled={isTyping}
          aria-label={isMicActive ? "Stop listening" : "Start listening"}
        >
          <i className={`bi ${isMicActive ? "bi-stop" : "bi-mic"}`}></i>
        </button>
      ) : (
        <div title="Mic is not supported in this browser">
          <button className="btn btn-light border p-3" disabled>
            <i className="bi bi-mic"></i>
          </button>
        </div>
      )}
    </>
  );
}

export default MicButton;
